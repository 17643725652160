import React from 'react'
import Helmet from 'react-helmet'
import {Link, graphql} from 'gatsby'
import Layout from '../components/Layout'
import {Card, ListGroupItem, ListGroup, Col, Row} from "reactstrap";
import BodySide from "../components/BodySide";

class CategoryRoute extends React.Component {

  constructor(props) {
    super(props);
    console.debug('CategoryRoute', this.props)
    this.updateDimensions = this.updateDimensions.bind(this);
  }

  componentDidMount() {
    window.addEventListener('load', this.updateDimensions);
    window.addEventListener('resize', this.updateDimensions);
    this.updateDimensions()
  }

  updateDimensions() {
    console.debug('updateDimensions', window.innerWidth)
    var ua = navigator.userAgent.toLowerCase();
    var isApp = ua.indexOf('anbai') > -1
    let element = document.getElementById('main-wrapper');
    if (isApp) {
      element.classList.add('is-app');
    } else {
      element.classList.remove('is-app');
    }
    // let element = document.getElementById('main-wrapper');
    // const width = window.innerWidth
    // if (width < 1170) {
    //   element.setAttribute('data-sidebartype', 'mini-sidebar');
    //   element.classList.add('mini-sidebar');
    // } else {
    //   element.setAttribute(
    //     'data-sidebartype',
    //     'full'
    //   );
    //   element.classList.remove('mini-sidebar');
    // }
  }

  componentWillUnmount() {
    window.removeEventListener('load', this.updateDimensions);
    window.removeEventListener('resize', this.updateDimensions);
  }

  render() {
    const edges = this.props.data.allMarkdownRemark.edges
    const {category, name, icon} = this.props.pageContext
    const title = this.props.data.site.siteMetadata.title

    return (
      <Layout breadcrumbs={[{name: name, link: '/category/' + category}]}>
        <Helmet title={`${name} | ${title}`} />
        <Row>
          <BodySide/>
          <Col md="8">
            <Card>
              <h3 className="my-4 mx-2 text-center"><i className={icon}/> {' '} {name}</h3>
              <ListGroup flush>
                {edges && edges.map(({node}, i) => (
                  <ListGroupItem key={i}>
                    <Link to={node.fields.slug}>
                      {node.frontmatter.title}
                    </Link>
                  </ListGroupItem>
                ))}
              </ListGroup>
            </Card>
          </Col>
        </Row>
      </Layout>
    )
  }
}

export default CategoryRoute

export const pageQuery = graphql`
  query CategoryPage($category: String) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___index], order: ASC }
      filter: { frontmatter: { category: { eq: $category }, invisible:{ne: true} } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
  }
`
